export default function useApi(path: string, options: any = {}): Promise<any> {
  const token = useCookie("auth.token")
  let headers: any = {
    accept: "application/json",
  }
  if (token?.value) {
    headers["Authorization"] = `Bearer ${token?.value}`
  }
  return $be(`${path}`, {
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  })
}
