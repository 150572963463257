import useApiFetch from "~/layers/auth/composables/useApi"
import { employee } from "~/constants/apiRoute/humanResource"
import { useTable } from "~/stores/table"
import type { IResponder } from "~/interfaces/iResponder"

export const getEmployees = async (options: any = {}): Promise<IResponder> => {
  return useApiFetch(`${employee}`, {
    query: options,
  })
}
export const postEmployee = async (
  body: any,
  options: any,
): Promise<IResponder> => await useSave(`${employee}`, body, options)

export const showEmployee = async (
  id: string | undefined,
): Promise<IResponder> => await useApiFetch(`${employee}/${id}`)

export const getEmployeeRoles = async (id: string) => {
  return await useApiFetch(`${employee}/${id}/roles`)
}
export const syncEmployeeRoles = async (id: string, body: any) => {
  return await useApiFetch(`${employee}/${id}/roles`, {
    method: "POST",
    body: body,
  })
}

export const postChangePassword = async (id: string | undefined, body: any) => {
  return await useApiFetch(`${employee}/${id}/change-password`, {
    method: "POST",
    body: body,
  })
}

export const postUpdatePosition = async (id: string | undefined, body: any) => {
  return await useApiFetch(`${employee}/${id}/positions`, {
    method: "POST",
    body: body,
  })
}
export const removePosition = async (id: string | undefined, body: any) => {
  return await useApiFetch(`${employee}/${id}/positions`, {
    method: "DELETE",
    body: body,
  })
}

export const postChangeProfilePassword = async (
  id: string | undefined,
  body: any,
) => {
  return await useApiFetch(`${employee}/${id}/change-profile-password`, {
    method: "POST",
    body: body,
  })
}

export const changeStatusEmployee = async (id: string): Promise<IResponder> =>
  await useApiFetch(`${employee}/${id}/change-status`, {
    method: "POST",
  })
export const getManager = async (id: string) => {
  return await useApiFetch(`${employee}/${id}/get-manager`)
}
export const getTimesheetOvertime = async (
  id: string | string[] | undefined,
  options: any = {},
) => {
  return await useApiFetch(`${employee}/${id}/get-timesheet-overtime`, {
    query: options,
  })
}

export const checkIsEmailExists = async (
  email: string,
  id: string | null = null,
): Promise<boolean> => {
  const response: IResponder = await useApiFetch(
    `${employee}/check-is-email-exists`,
    {
      query: {
        email: email,
        id: id,
      },
    },
  )
  return response?.data?.exists === false
}

export const employeeAssessed = async () => {
  return useApiFetch(`${employee}/employee-assessed`)
}
export const employeeAssess = async (id: string) => {
  return useApiFetch(`${employee}/${id}/employee-assess`)
}

export const employeeAssessment = async (id: string, body: any) => {
  return await useApiFetch(`${employee}/${id}/assessment`, {
    method: "POST",
    body: body,
  })
}

export const postContactInformation = async (id: string, body: any) => {
  return useApiFetch(`${employee}/${id}/contact-information`, {
    method: "POST",
    body: body,
  })
}

export const postFileEmployee = async (id: string, body: any) =>
  await useApiFetch(`${employee}/${id}/files`, {
    method: "POST",
    body: body,
  })

export const employeeProfile = (id: string) => {
  const store = useTable()
  const dataChanged = computed({
    get: () => store.dataChanged,
    set: (val) => (store.dataChanged = val),
  })
  const { status, error, data } = useLazyAsyncData("item", async () => {
    const response: IResponder = await showEmployee(id)
    if (response.success) {
      return response.data
    }
    return null
  })
  const refresh = async () => await refreshNuxtData("item")
  watch(dataChanged, async (val) => {
    if (val) {
      await refresh()
      dataChanged.value = false
    }
  })
  return {
    status,
    error,
    data,
  }
}

export const postEmployeeBankAccount = async (
  body: any,
  options: any,
): Promise<IResponder> =>
  await useSave(`${employee}/bank-account`, body, options)

export const showEmployeeBankAccount = async (
  id?: string,
): Promise<IResponder> => await useApiFetch(`${employee}/bank-account/${id}`)

export const getEmployeeBankAccount = async (
  userId?: string,
  query: any,
): Promise<IResponder> =>
  await useApiFetch(`${employee}/bank-account/employee/${userId}`, {
    query: query,
  })
